import { SVGProps, Ref, forwardRef } from 'react'

const SvgChevronDown = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m19 8-6.67 6.86A.466.466 0 0 1 12 15a.455.455 0 0 1-.33-.14L5 8"
    />
  </svg>
)

const ForwardRef = forwardRef(SvgChevronDown)
export default ForwardRef
